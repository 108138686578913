<template>
  <b-modal
    id="modal-level"
    v-model="show"
    :title="title"
    :size="form.type ? 'xl' : 'md'"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col :class="{ 'border-right': form.type }">
            <b-row>
              <b-col>
                <basic-input
                  required
                  label="Mã Câu hỏi"
                  placeholder="Nhập mã câu hỏi"
                  name="code"
                  :state="validateState('code')"
                  :invalidFeedback="errors.first('code')"
                  v-validate="{
                    required: true,
                    alpha_num: true,
                    max: 50,
                  }"
                  data-vv-as="Mã câu hỏi"
                  :value.sync="form.code"
                  maxLength="50"
                  :disabled="isDetail"
                />
              </b-col>
              <b-col>
                <basic-select
                  label="Loại câu hỏi"
                  placeholder="--- Chọn loại câu hỏi ---"
                  required
                  name="type"
                  :solid="false"
                  :allowEmpty="false"
                  value-label="text"
                  track-by="value"
                  :state="validateState('type')"
                  :invalidFeedback="errors.first('type')"
                  v-validate="{
                    required: true,
                  }"
                  data-vv-as="Loại câu hỏi"
                  :options="answerTypes"
                  :value.sync="form.type"
                  :disabled="isDetail"
                  @update:value="selectType"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Trạng thái"
                  placeholder="--- Chọn trạng thái ---"
                  name="status"
                  :solid="false"
                  :allowEmpty="false"
                  value-label="text"
                  track-by="value"
                  :options="statuses"
                  disabled
                  :value.sync="form.status"
                  @update:value="selectStatus"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-input
                  required
                  label="Tên câu hỏi"
                  placeholder="Nhập tên câu hỏi"
                  name="title"
                  :state="validateState('title')"
                  :invalidFeedback="errors.first('title')"
                  v-validate="{
                    required: true,
                    max: 500,
                  }"
                  data-vv-as="Tên câu hỏi"
                  :value.sync="form.name"
                  :disabled="isDetail"
                  maxlength="500"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Cấp độ"
                  placeholder="--- Chọn cấp độ liên quan ---"
                  name="level"
                  :solid="false"
                  :allowEmpty="true"
                  value-label="text"
                  track-by="value"
                  multiple
                  required
                  :state="validateState('level')"
                  :invalidFeedback="errors.first('level')"
                  v-validate="{
                    required: true,
                  }"
                  scope="level"
                  data-vv-as="Cấp độ liên quan"
                  :options="levels"
                  :value.sync="form.lstLevelId"
                  :disabled="isDetail"
                  @update:value="selectLevel"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-select
                  label="Bài học liên quan"
                  placeholder="--- Chọn bài học liên quan ---"
                  name="lesson"
                  :solid="false"
                  :allowEmpty="true"
                  value-label="text"
                  track-by="value"
                  multiple
                  required
                  :state="validateState('lesson')"
                  :invalidFeedback="errors.first('lesson')"
                  v-validate="{
                    required: true,
                  }"
                  data-vv-as="Bài học liên quan"
                  :options="lessons"
                  :disabled="isDetail"
                  :value.sync="form.lstLessonId"
                  @update:value="selectLesson"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-show="form.type">
            <div class="mb-8">
              <label class="font-weight-bolder mb-3"
                >Định nghĩa câu trả lời và đáp an đúng cho câu hỏi</label
              >
              <v-data-table
                :headers="headers"
                :items="quizes"
                id="sortable"
                hide-default-footer
                ref="sortableTable"
                :items-per-page="-1"
              >
                <template v-slot:item="{ item }">
                  <!-- :key="item.id" -->
                  <tr
                    v-if="!item.isAddNew"
                    :key="itemKey(item)"
                    class="cursor-pointer sortableRow position-static"
                  >
                    <td v-if="!isDetail" class="mw-100 handle text-overflow">
                      <div class="d-flex w-100 align-items-center">
                        <div class="svg-icon svg-icon-sm text-overflow">
                          <inline-svg
                            class="svg-icon mr-2 mw-30 sortHandle"
                            src="/media/svg/icons/Neolex/Basic/menu-dots.svg"
                          />
                        </div>
                      </div>
                    </td>
                    <td>{{ item.name }}</td>
                    <td class="text-center">
                      <b-form-radio
                        v-if="isSingleChoices"
                        v-model="item.isCorrect"
                        size="lg"
                        :value="true"
                        class="radio-answer"
                        name="some-radios"
                        :disabled="isDetail"
                        @change="changeAnswerCorrect(item)"
                      ></b-form-radio>
                      <b-form-checkbox
                        v-else
                        v-model="item.isCorrect"
                        class="checkbox-answer"
                        :disabled="isDetail"
                      >
                      </b-form-checkbox>
                    </td>
                    <td v-if="!isDetail">
                      <b-button
                        class="btn btn-delete ml-0"
                        size="sm"
                        type="button"
                        @click="removeAnswer(item)"
                      >
                        <span class="svg-icon mr-0">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/trash-2.svg"
                          />
                        </span>
                      </b-button>
                    </td>
                  </tr>
                  <tr v-else class="cursor-pointer sortableRow position-static">
                    <td></td>
                    <td>
                      <div class="py-3">
                        <basic-input
                          :name="`add-answer`"
                          :value.sync="quize.name"
                          :required="true"
                          :state="validateState(`add-answer`)"
                          :invalid-feedback="errors.first(`add-answer`)"
                          v-validate="{
                            required: isRequireAnswer,
                            max: 500,
                          }"
                          data-vv-as="Câu trả lời"
                          class="score-input"
                          placeholder="Thêm câu trả lời"
                          maxlength="500"
                          ref="addAnswer"
                        ></basic-input>
                      </div>
                    </td>
                    <td class="text-center">
                      <b-form-radio
                        v-if="isSingleChoices"
                        v-model="item.isCorrect"
                        size="lg"
                        :value="true"
                        class="radio-answer"
                        name="some-radios"
                      ></b-form-radio>
                      <b-form-checkbox
                        v-else
                        v-model="item.isCorrect"
                        class="checkbox-answer"
                      >
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-button
                        class="btn btn-save ml-0"
                        size="sm"
                        type="button"
                        @click="addAnswer(item)"
                      >
                        <span class="svg-icon mr-0">
                          <inline-svg
                            src="/media/svg/icons/Neolex/Basic/save.svg"
                          />
                        </span>
                      </b-button>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <span v-if="showErrorAnswers" class="text-danger"
                >Câu trả lời cho câu hỏi là chưa phù hợp</span
              >
            </div>
            <basic-text-area
              label="Câu giải thích cho đáp án đúng"
              placeholder="Nhập câu giải thích cho các đáp án đúng cho câu hỏi"
              name="description"
              :value.sync="form.explain"
              :disabled="isDetail"
              maxlength="500"
              :required="requiredExplain"
              :state="validateState(`description`)"
              :invalid-feedback="errors.first(`description`)"
              v-validate="{
                required: requiredExplain,
              }"
              data-vv-as="Câu giải thích cho đáp án đúng"
            />
          </b-col>
        </b-row>
        <div
          v-if="!isDetail"
          class="d-flex justify-content-end align-items-center"
        >
          <b-button class="btn" type="button" @click="resetModal">
            Huỷ
          </b-button>
          <template v-if="isEdit">
            <b-button
              v-if="showBtnActive"
              class="btn btn-primary ml-3"
              type="button"
              @click="showPopupChangeStatus(1)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Active
            </b-button>
            <b-button
              v-else
              class="btn btn-danger ml-3"
              type="button"
              @click="showPopupChangeStatus(0)"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/power.svg" />
              </span>
              Inactive
            </b-button>
          </template>
          <b-button class="btn btn-success ml-3" type="submit">
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
            </span>
            Lưu
          </b-button>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import Sortable from 'sortablejs';
import { differenceBy, sortBy } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { SET_MODAL } from '@/core/services/store/context.module';
import { MODAL_TYPE } from '@/core/plugins/constants';
import {
  GET_DATA_PREPARE,
  CREATE_QUIZE,
  UPDATE_QUIZE,
  CHANGE_STATUS,
} from '@/core/services/store/quize.module';
const { mapState, mapMutations } = createNamespacedHelpers('context');
const { mapState: quizeState, mapActions: quizeActions } =
  createNamespacedHelpers('quize');
export default {
  name: 'ModalQuize',
  props: {
    type: {
      type: String,
      default: MODAL_TYPE.DETAIL,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        code: null,
        type: {
          text: 'Single Choices',
          value: 1,
        },
        lstLevelId: [],
        lstLessonId: null,
        status: null,
        explain: null,
        name: null,
      },
      loading: false,
      quizes: [],
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      correctAnswers: [],
      quize: {
        name: null,
        isAddNew: true,
        isCorrect: false,
      },
      answerTypes: [
        {
          text: 'Single Choices',
          value: 1,
        },
        {
          text: 'Multiple Choices',
          value: 2,
        },
      ],
      levels: [],
      lessons: [],
      statuses: [],
      isRequireAnswer: false,
      showErrorAnswers: false,
      isPressActive: false,
    };
  },
  computed: {
    ...mapState(['modalShow']),
    ...quizeState(['dataPrepare']),
    show: {
      get() {
        return this.modalShow;
      },
      set(val) {
        this.SET_MODAL(val);
      },
    },
    title() {
      switch (this.type) {
        case MODAL_TYPE.CREATE:
          return 'Tạo câu hỏi mới';
        case MODAL_TYPE.COPY:
          return 'Sao chép hỏi mới';
        case MODAL_TYPE.EDIT:
          return `Chỉnh sửa câu hỏi ${this.form.name || ''}`;

        default:
          return `Chi tiết câu hỏi ${this.form.name || ''}`;
      }
    },
    isSingleChoices() {
      return this.form.type?.value === 1;
    },
    isDetail() {
      return this.type === MODAL_TYPE.DETAIL;
    },
    headers() {
      if (!this.isDetail) {
        return [
          {
            text: '',
            value: 'actionMove',
            sortable: false,
            width: '1%',
          },
          {
            text: 'Câu trả lời',
            value: 'answer',
            sortable: false,
          },
          {
            text: 'Câu đúng',
            value: 'answerCorrect',
            sortable: false,
            align: 'center',
          },
          {
            text: '',
            value: 'action',
            sortable: false,
            width: '1%',
          },
        ];
      }
      return [
        {
          text: 'Câu trả lời',
          value: 'answer',
          sortable: false,
        },
        {
          text: 'Câu đúng',
          value: 'answerCorrect',
          sortable: false,
          align: 'center',
        },
      ];
    },
    isCopy() {
      if (this.id && this.type === MODAL_TYPE.COPY) return true;
      return false;
    },
    isEdit() {
      return this.type === MODAL_TYPE.EDIT;
    },
    disableCode() {
      return [MODAL_TYPE.DETAIL, MODAL_TYPE.EDIT].includes(this.type);
    },
    showBtnActive() {
      if ([0, 2].includes(this.dataPrepare?.status)) return true;
      return false;
    },
    isActive() {
      return this.dataPrepare?.status === 1;
    },
    requiredExplain() {
      return (
        (this.isActive || this.showBtnActive) &&
        this.isEdit &&
        this.isPressActive
      );
    },
  },
  watch: {
    'form.explain': function (val) {
      this.isPressActive = !!val;
    },
  },
  created() {
    this.loadData();
    this.form.lstLevelId = this.lstLevels;
  },
  mounted() {
    setTimeout(() => {
      new Sortable(
        this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
        {
          draggable: '.sortableRow',
          handle: '.sortHandle',
          onEnd: this.dragReorder,
        },
      );
    }, 100);
  },
  methods: {
    ...mapMutations({ SET_MODAL }),
    ...quizeActions({
      GET_DATA_PREPARE,
      CREATE_QUIZE,
      UPDATE_QUIZE,
      CHANGE_STATUS,
    }),
    async loadData() {
      // load data here
      this.loading = true;
      await this.GET_DATA_PREPARE({ id: this.id });
      if (this.dataPrepare) {
        this.levels = this.dataPrepare.lessonLevels;
        this.statuses = this.dataPrepare.quizStates.map((el) => {
          return {
            ...el,
            value: Number(el.value),
          };
        });
        this.form.status = this.statuses.find((el) => Number(el.value) === 2);
        this.lessons = this.dataPrepare.lessons;
        if (this.dataPrepare.quizAnswers) {
          this.quizes = sortBy(this.dataPrepare.quizAnswers, 'order');
        }
        if (!this.isDetail && this.quizes.length < 10) {
          this.quizes.push(this.quize);
        }
        // fill data for view + edit
        if (this.type !== MODAL_TYPE.CREATE) {
          this.form.lstLevelId = this.levels.filter((el) => el.selected);
          this.form.lstLessonId = this.lessons.filter((el) => el.selected);
          this.form.code = this.dataPrepare.code;
          this.form.name = this.dataPrepare.name;
          this.form.explain = this.dataPrepare.explain;
          this.form.type = this.answerTypes.find(
            (el) => el.value === this.dataPrepare.type,
          );
          this.form.status = this.statuses.find(
            (el) => el.value === this.dataPrepare.status,
          );
        }

        // fill data for copy
        if (this.isCopy) {
          this.form.lstLevelId = this.levels.filter((el) => el.selected);
          this.form.lstLessonId = this.lessons.filter((el) => el.selected);
          this.form.name = this.dataPrepare.name;
          this.form.explain = this.dataPrepare.explain;
          this.form.type = this.answerTypes.find(
            (el) => el.value === this.dataPrepare.type,
          );
          this.form.status = this.statuses.find((el) => el.value === 2);
        }
      }

      this.isPressActive = this.isActive;

      this.loading = false;
    },
    resetModal() {
      this.$emit('reset');
    },
    submit() {
      const answers = this.quizes.filter((el) => !el.isAddNew);
      let payload = {
        name: this.form.name.trim(),
        code: this.form.code.trim(),
        explain: this.form.explain ? this.form.explain.trim() : null,
        status: this.form.status.value,
        type: this.form.type.value,
        lstAnswer: answers.length
          ? answers.map((el, index) => {
              if (this.type === MODAL_TYPE.CREATE) {
                delete el.id;
              }
              return {
                ...el,
                order: index + 1,
              };
            })
          : [],
      };

      if (this.type === MODAL_TYPE.CREATE || this.isCopy) {
        payload = {
          ...payload,
          lstLevelId: this.form.lstLevelId.map((el) => el.value) || [],
          lstLessonId: this.form.lstLessonId.map((el) => el.value) || [],
        };
        return this.createQuize(payload);
      }
      const levelDefault = this.levels.filter((el) => el.selected);
      const lessonDefault = this.lessons.filter((el) => el.selected);
      const [lstLevelCreateId, lstLevelRemoveId] = this.getItemAddAndRemove(
        levelDefault,
        this.form.lstLevelId,
      );
      const [lstLessonCreateId, lstLessonRemoveId] = this.getItemAddAndRemove(
        lessonDefault,
        this.form.lstLessonId,
      );
      if (lstLevelCreateId.length) {
        payload['lstLevelCreateId'] = lstLevelCreateId.map((el) => el.value);
      }
      if (lstLevelRemoveId.length) {
        payload['lstLevelRemoveId'] = lstLevelRemoveId.map((el) => el.value);
      }
      if (lstLessonCreateId.length) {
        payload['lstLessonCreateId'] = lstLessonCreateId.map((el) => el.value);
      }
      if (lstLessonRemoveId.length) {
        payload['lstLessonRemoveId'] = lstLessonRemoveId.map((el) => el.value);
      }
      payload['id'] = this.id;
      return this.updateQuize(payload);
    },
    getItemAddAndRemove(defaultItems, beforeSubmitItems) {
      const removeItems = differenceBy(
        defaultItems,
        beforeSubmitItems,
        'value',
      );

      const addItems = differenceBy(beforeSubmitItems, defaultItems, 'value');
      return [addItems, removeItems];
    },
    async createQuize(params) {
      this.loading = true;
      const { error } = await this.CREATE_QUIZE(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Tạo mới thành công',
        });
        this.$emit('submit');
      }
      this.loading = false;
    },
    async updateQuize(params) {
      this.loading = true;
      const { error } = await this.UPDATE_QUIZE(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhật thành công',
        });
        this.$emit('submit');
      }
      this.loading = false;
    },
    async handleValidate() {
      await (this.isPressActive = this.isActive);
      if (!this.quizes[0].name) {
        await (this.isRequireAnswer = !this.quizes[0].name);
      } else {
        await (this.showErrorAnswers = this.quizes.length <= 1);
        await (this.isRequireAnswer = false);
      }
      if (!this.showBtnActive) {
        await this.showErrorAnswersNotCorrect();
      }

      this.$validator.validateAll().then((result) => {
        if (result && !this.isRequireAnswer && !this.showErrorAnswers) {
          this.submit();
        }
        return;
      });
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    showErrorAnswersNotCorrect() {
      const answers = this.quizes.filter((el) => !el.isAddNew);

      if (!answers.length) return false;

      if (answers.length === 1 && !this.isSingleChoices)
        return (this.showErrorAnswers = true);

      const itemsCorrect = answers.filter((el) => el.isCorrect);
      return (this.showErrorAnswers = !(itemsCorrect.length > 0));
    },
    itemKey(item) {
      if (!this.itemKeys.has(item))
        this.itemKeys.set(item, ++this.currentItemKey);
      return this.itemKeys.get(item);
    },
    dragReorder({ oldIndex, newIndex }) {
      const movedItem = this.quizes.splice(oldIndex, 1)[0];
      this.quizes.splice(newIndex, 0, movedItem);
    },
    async addAnswer(item) {
      const newItem = {
        name: item.name ? item.name.trim() : null,
        isCorrect: item.isCorrect,
        id: this.$uuid.v1(),
      };
      await (this.isRequireAnswer = !newItem.name);

      const answerValid = await this.$validator.validate('add-answer');
      if (!answerValid) return;

      if (this.form.type.value === 1 && newItem.isCorrect) {
        this.quizes.forEach((el) => {
          if (el.isCorrect) {
            el.isCorrect = false;
          }
        });
      }
      this.quizes = this.insert(this.quizes, this.quizes.length - 1, newItem);
      if (this.quizes.length >= 11) {
        this.quizes = [...this.quizes].filter((el) => !el.isAddNew);
      }
      this.quize.name = null;
      this.quize.isCorrect = false;
      this.showErrorAnswers = false;
    },
    insert(arr, index, newItem) {
      return [...arr.slice(0, index), newItem, ...arr.slice(index)];
    },
    selectType(value) {
      this.form.type = this.selectSingleItem(value, this.answerTypes);
      this.quizes = [this.quizes[this.quizes.length - 1]];
    },
    selectStatus(value) {
      this.form.status = this.selectSingleItem(value, this.statuses);
    },
    async selectLevel(value) {
      this.form.lstLevelId = this.selectMultipleItems(value, this.levels);
      value && (await this.$validator.reset({ name: 'level' }));
    },
    async selectLesson(value) {
      this.form.lstLessonId = this.selectMultipleItems(value, this.lessons);
      value && (await this.$validator.reset({ name: 'lesson' }));
    },
    selectSingleItem(value, array) {
      let selectedItem = null;
      if (value) {
        selectedItem = array.find((item) => item.value === value);
      }
      return selectedItem;
    },
    selectMultipleItems(value, array) {
      const selectedItems = [];
      if (value && value.length) {
        value.forEach((el) => {
          const selected = array.find((item) => item.value === el);
          selectedItems.push(selected);
        });
      }
      return selectedItems;
    },
    removeAnswer(item) {
      const deleteIndex = this.quizes.findIndex((el) => el.id === item.id);
      this.quizes.splice(deleteIndex, 1);
      const indexAddNew = this.quizes.findIndex((el) => el.isAddNew);
      if (indexAddNew === -1) {
        this.quizes.push(this.quize);
      }
    },
    changeAnswerCorrect(value) {
      this.quizes.forEach((el) => {
        if (el.id !== value.id) {
          el.isCorrect = false;
        }
      });
    },
    async showPopupChangeStatus(status) {
      await (this.isPressActive = true);
      await this.delay(100);
      const valid = await this.$validator.validate('description');
      if (this.showBtnActive) {
        await this.showErrorAnswersNotCorrect();
      }
      if (!valid || this.showErrorAnswers) return;
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          status ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(status);
          }
        }.bind(this),
      );
    },
    async changeStatus(status) {
      this.loading = true;
      const params = {
        id: this.id,
        state: status,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } else {
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Cập nhật thành công',
        });
        this.loadData();
        this.$emit('change-status');
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.btn-save {
  color: #008479 !important;
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
}
.btn-delete {
  color: #ff5756 !important;
  background-color: #ffebef !important;
  border-color: #ffebef !important;
}

.btn.btn-danger {
  color: #fff !important;
  background-color: #ff5756 !important;
  border: none !important;
}
</style>

<style lang="scss">
.checkbox-answer .custom-control-label::before,
.checkbox-answer .custom-control-label::after {
  left: -1.55rem;
}

.radio-answer .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #01645a !important;
  background-color: #01645a !important;
}
.score-input {
  .form-group {
    margin-bottom: 0 !important;
  }
}
</style>
